<template>
  <dash-layout>
    <template slot="dash-title">Districts</template>
    <template slot="dash-body">
      <v-card max-width="100%">
        <v-card-title class="mb-6">
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
            clearable></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" @click="$router.push(`/districts/new`).catch((err) => {})" color="blue-grey darken-1" :disabled="this.user.role === 'User'">
            New District
          </v-btn>
          <!-- <v-btn @click="$router.push(`/elections`).catch((err) => {})" class="ml-2" color="blue-grey darken-1">
            Go To Elections
          </v-btn> -->
        </v-card-title>

        <v-container grid-list-xl fluid>
          <v-layout row wrap>
            <v-data-table :headers="headers" :items="dis_data" :search="search" class="elevation-1"
              :loading="districtLoading" loading-text="Districts Data Loading ..." style="width: 100%">

              <!-- Slot for the district name to make it clickable -->
              <template v-slot:[`item.name`]="{ item }">
                <v-btn :style="{ textTransform: 'none' }" text @click="openDistrict(item)" v-bind="ttprops" v-on="on">
                  {{ item.name }}
                </v-btn>
              </template>

              <template v-slot:[`item.district_name`]="{ item }">
                {{ item.address && (item.address + ',') }} {{ item.district_name }}, {{ item.state_name }}, {{
                  item.country_name }}
              </template>
              <template v-slot:[`item.district_contacts`]="{ item }">
                <v-span 
                 style="border-bottom: 1px solid; cursor: pointer;"
                 @click=" $router.push({name:`DistrictView` , params: {id: item.id ,activetab: 'Contacts'}})">
                  Contacts
                </v-span>
              </template>
              <template v-slot:[`item.district_elections`]="{ item }" >
                <v-span
                  style="border-bottom: 1px solid; cursor: pointer;"
                  @click="$router.push({ name: 'ElectionList', params: { districtId: item.id } }).catch((err) => {})"
                  >Elections</v-span
                >
              </template>
              <template v-slot:[`item.district_audits`]="{ item }">
                <v-span
                  style="border-bottom: 1px solid; cursor: pointer;"
                  @click="$router.push({ name: 'AuditJobsList', params: { districtName: item.district_name } }).catch((err) => {})"
                  > Audits</v-span>
              </template>
              <template v-slot:[`item.actions`]="{ item }" v-if="this.user.role !== 'User'">
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn icon @click.prevent="openDistrict(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit District</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.actions`]="{ item }" v-else>
                -
              </template>
            </v-data-table>
          </v-layout>
        </v-container>
      </v-card>
    </template>
  </dash-layout>
</template>
<script>
import { mapGetters } from 'vuex'
import DashLayout from '../../containers/Layout'
export default {
  name: 'DistrictsList',
  components: {
    DashLayout,
  },
  computed: {
    ...mapGetters(['districts', 'districtLoading', 'user']),
  },
  async mounted() {
    await this.$store.dispatch('loadDistricts', this.user.id)
    this.userValue = this.user
    setTimeout(() => {
      this.dis_data = this.districts
      if (this.$route.params?.districtname) {
        this.search = this.$route.params?.districtname
      }
    }, 1000)

  },
  data() {
    return {
      userValue: {},
      isHidden: false,
      AddDistrictDetails: false,
      waiting: false,
      contactName: '',
      address: '',
      city: '',
      statePostalCode: '',
      phoneNumber: '',
      email: '',
      search: '',
      website: '',
      uploadlink: '',
      electionList: '',
      districtDeleteDialog: false,
      dis_data: [],
      rules: {
        required: (value) => !!value || 'Required.',
      },
      headers: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Location', value: 'district_name' },
        { text: 'Contacts', value: 'district_contacts' },
        { text: 'Elections', value: 'district_elections' },
        { text: 'Audits', value: 'district_audits' },
        // { text: 'Access Date', value: 'date', sortable: true, defaultSort: 'desc' },
        // { text: 'Date', value: 'created_at' },
        // { text: 'Role', value: 'role' },
        { text: 'Actions', value: 'actions' },
      ],
    }
  },
  methods: {
    openAddDistrictDeatil() {
      this.AddDistrictDetails = true
    },

    async openDistrict(item) {
      this.$router.push(`/districts/${item.id}`)
    },
  },
}
</script>

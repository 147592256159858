<template>
  <v-card max-width="100%">
    <v-dialog v-model="toolsDialog" max-width="600px" persistent>
      <v-card class="pa-2">
        <v-card-title>
          <div>Tools</div>
        </v-card-title>

        <v-container>
          <v-row class="reduce-row-gaps">
            <v-col cols="12">
              <v-text-field v-model="finction_name" label="Function Name" outlined></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field v-model="script" label="Script" outlined></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field v-model="api_entry" label="Api Entry" outlined></v-text-field>
            </v-col>

            <v-col cols="12">
              <label for="">Api Operands</label>
              <v-jsoneditor v-model="api_operands" :options="options" :plus="false" :height="`300px`"
                :expandedOnStart="true" />
            </v-col>
            <v-col cols="12">
              <v-textarea clearable clear-icon="cancel" label="Help Text" v-model="help_text" outlined :readonly="waiting"
                rows="5"></v-textarea>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="toolsDialog = false" :disabled="waiting">Close</v-btn>
          <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" color="blue darken-1" outlined @click="save" :disabled="waiting || !canSend">
            {{ id > 0 ? 'Update' : 'Add' }}
            <v-progress-circular indeterminate color="primary" v-show="waiting"></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="toolDeleteDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>Are you sure deleting tools ?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn class="btn-outline" outlined @click="toolDeleteDialog = false">Cancel</v-btn>
          <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" color="error" outlined @click="deleteToolConfirm" :disabled="waiting">
            Delete
            <v-progress-circular v-if="waiting" :width="3" color="green" indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="90%" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ operation_name }}</span>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-textarea id="operation_log_screen" label="Operation logs" :value="operation_logs" rows="20"
            :readonly="true"></v-textarea>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeLogs()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title class="mb-6">
      <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
        clearable></v-text-field>
      <v-spacer></v-spacer>

      <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" dark color="blue-grey darken-1" @click.prevent="addNewTool">
        <v-icon left>mdi-tools</v-icon> Add Tool
      </v-btn>
    </v-card-title>
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-data-table v-model="selectedOperations" :headers="headers" :items="operations" :search="search"
          class="elevation-1" :loading="actionLoadingTools" item-key="operation" loading-text="Loading Tools ..."
          style="width: 100%" show-select hide-default-footer :single-expand="singleExpand" :expanded.sync="expanded"
          show-expand :items-per-page="50">
          <template v-slot:item.running="{ item }">
            <!-- <v-btn v-if=" item.status == 'SUCCESS'" text small v-on:click="launchOperationforce(item)" :disabled="activeAuditJob.role != 'Auditor'"> <v-icon>mdi-refresh</v-icon></v-btn> -->
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, ttprops }">
                <v-btn v-if="item.status == 'RUNNING'" @click="stopOperation(item, operations.indexOf(item))"
                  v-bind="ttprops" v-on="on"><v-icon>mdi-stop</v-icon></v-btn>
                <v-btn v-else-if="item.status == 'unbuilt-ready'" text small
                  @click="launchOperation(item, operations.indexOf(item))" :disabled="activeAuditJob.role != 'Auditor'"
                  v-bind="ttprops" v-on="on"><v-icon>mdi-play</v-icon></v-btn>
                <v-btn v-else-if="item.status == 'STOPPED' || item.status == 'FAILURE' || item.status == 'SUCCESS'" text
                  small v-on:click="resetOperation(item, operations.indexOf(item))"
                  :disabled="activeAuditJob.role != 'Auditor'" v-bind="ttprops"
                  v-on="on"><v-icon>mdi-refresh</v-icon></v-btn>
              </template>
              <span>Media Operation</span>
            </v-tooltip>

          </template>
          <template v-slot:item.percent="{ item }">
            <v-progress-linear height="25" :value="item.status == 'SUCCESS' ? '100' : item.percent"
              :color="item.status == 'SUCCESS' ? 'green' : 'amber'">
              <template v-slot="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </template>
          <template v-slot:item.reports="{ item }">
            <div class="d-flex"
              :class="item.status == 'built' || item.status == 'RUNNING' || item.status == 'STOPPED' ? 'active-report' : 'deactive-report'">
              <v-menu v-if="item.reports.length > 0" :rounded="'b-lg'" offset-y>
                <template v-slot:activator="{ attrs, on }">
                  <v-icon v-bind="attrs" v-on="on">mdi-file-multiple</v-icon>
                </template>
                <v-list color="grey darken-3">
                  <v-list-item v-for="(report, index) in item.reports" :key="index" link>
                    <v-list-item-title v-text="report.text" @click="openLink(report)"></v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                </v-list>
              </v-menu>
              <!-- <div v-else>Not Ready</div> -->
            </div>
          </template>
          <template v-slot:item.downloads="{ item }">
            <div class="d-flex" :class="item.status == 'built' ? 'active-report' : 'deactive-report'">
              <v-menu v-if="item.downloads && item.downloads.length > 0" :rounded="'b-lg'" offset-y>
                <template v-slot:activator="{ attrs, on }">
                  <v-icon v-bind="attrs" v-on="on">mdi-download</v-icon>
                </template>
                <v-list color="grey darken-3">
                  <v-list-item v-for="(results, index) in item.downloads" :key="index" link>
                    <v-list-item-title v-text="results.text" @click="openResult(results)"></v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                </v-list>
              </v-menu>
            </div>
          </template>

          <template v-slot:item.logs="{ item }">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, ttprops }">
                <v-btn text small @click="viewLogs(item)" :disabled="item.status == 'unbuilt-notready'" v-bind="ttprops"
                  v-on="on"><v-icon>mdi-eye</v-icon></v-btn>
              </template>
              <span>View Logs</span>
            </v-tooltip>


          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <!-- <v-icon>mdi-information-outline</v-icon> -->
            <td :colspan="headers.length">
              <p style="white-space: pre-wrap;">{{ item.help }}</p>
            </td>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, ttprops }">
                <v-btn icon @click.prevent="showToolsDialog(item, operations.indexOf(item))" v-bind="ttprops" v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Show Tools Dialog</span>
            </v-tooltip>

            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, ttprops }">
                <v-btn icon @click.prevent="deleteTools(operations.indexOf(item))" v-bind="ttprops" v-on="on">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete Tools</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-layout>
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Vue from 'vue'
import axios from 'axios'
import DashLayout from '../../containers/Layout'
import VJsoneditor from 'v-jsoneditor/src/index'
export default {
  name: 'AuditTools',
  components: {
    DashLayout,
    VJsoneditor,
  },
  computed: {
    ...mapGetters(['actionTools', 'actionLoadingTools', 'user', 'addtoolsLoading', 'activeAuditJob', 'statusTools']),
    canSend: function () {
      return (
        this.finction_name != '' &&
        this.script != '' &&
        this.help_text != '' &&
        this.api_entry != '' &&
        this.api_operands
      )
    },
  },
  mounted() {
    this.$store.dispatch('loadactiontools')
    this.userValue = this.user
  },
  data() {
    return {
      userValue: {},
      isHidden: false,
      expanded: [],
      singleExpand: true,
      overlay: false,
      id: 0,
      search: '',
      finction_name: '',
      script: '',
      help_text: '',
      api_entry: '',
      waiting: false,
      toolsDialog: false,
      currentIndex: '',
      toolDeleteDialog: false,
      deleteRownumber: 0,
      operations: [],
      selectedOperations: [],
      operation_logs: '',
      operation_name: '',
      operation_id: '',
      options: {
        mode: 'code'
      },
      headers: [
        { text: 'Function Name', value: 'function_name', sortable: false, width: '20%' },
        { text: 'Action', value: 'running', sortable: false, width: '5%' },
        { text: 'Status', value: 'status', sortable: false, width: '10%' },
        { text: 'Percent', value: 'percent', sortable: false, width: '15%' },
        { text: 'Reports', value: 'reports', sortable: false, width: '15%' },
        { text: 'Downloads', value: 'downloads', sortable: false, width: '15%' },
        { text: 'script', value: 'script', sortable: false, width: '10%' },
        { text: 'Api Entry', value: 'api_entry', sortable: false, width: '10%' },
        { text: 'Logs', value: 'logs', sortable: false, width: '10%' },
        { text: 'Help Text', value: 'data-table-expand', width: '5%' },
        // { text: 'Help Text', value: 'help_text', sortable: true, width: '20%' },
        { text: 'Actions', value: 'actions', sortable: false, width: '20%' },
      ],
      files: [],
      jobFileCreateErrorDialog: false,
      api_operands: {},
      dialog: false,
      log_timer: null,
    }
  },

  methods: {
    async onTabChange(selectedGroupId) {
      console.log(selectedGroupId)
    },
    showToolsDialog(item, index) {
      this.currentIndex = index
      this.id = 1
      this.finction_name = item.function_name
      this.script = item.script
      this.api_entry = item.api_entry
      this.api_operands = item.api_operands
      this.help_text = item.help
      this.toolsDialog = true
      this.toolDeleteDialog = false
    },
    deleteTools(index) {
      this.currentIndex = index
      this.toolDeleteDialog = true
    },
    async deleteToolConfirm() {
      this.waiting = true
      let data = {
        job_id: this.activeAuditJob.id,
        row_number: this.actionTools[this.currentIndex].row_number,
      }
      await this.$store.dispatch('deleteTools', data)
      this.waiting = false
      this.toolDeleteDialog = false
    },
    addNewTool() {
      this.currentIndex = ''
      this.id = 0
      this.finction_name = ''
      this.script = ''
      this.api_entry = ''
      this.api_operands = {}
      this.help_text = ''
      this.toolsDialog = true
    },
    async save() {
      this.waiting = true
      let payload = {
        function_name: this.finction_name,
        script: this.script,
        api_entry: this.api_entry,
        api_operands: JSON.stringify(this.api_operands),
        help_text: this.help_text,
      }
      let data = {
        job_id: this.activeAuditJob.id,
        settings: JSON.stringify(payload),
      }
      if (this.id != 0) {
        data['row_number'] = this.actionTools[this.currentIndex].row_number
      }
      await this.$store.dispatch('addtools', data)
      this.waiting = false
      this.toolsDialog = false
    },
    viewLogs(item) {
      console.log('item', item)
      this.operation_name = item.operation
      this.operation_id = item.operation_id
      this.log_timer = setInterval(this.updateLogs, 1500)
      this.dialog = true

    },
    async updateLogs() {
      const url = process.env.VUE_APP_ROOT_API + '/logs/operation-logs/' + this.operation_name + '/log.txt'
      axios.get(url, {
        crossDomain: true,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'

      })
        .then(response => {
          if (this.operation_logs != response.data) {
            this.operation_logs = response.data
            var textarea = document.getElementById('operation_log_screen')
            textarea.scrollTop = textarea.scrollHeight;
          }
        })
        .catch(e => {
          console.log('error', e)
        })
    },
    closeLogs() {
      clearInterval(this.log_timer)
      this.dialog = false
    },
    resetOperation(item, index) {
      item['operation_id'] = '';
      item['status'] = "unbuilt-ready";
      this.operations[index] = item;
    },
    async launchOperation(item, index) {
      let payload = {
        ...item.api_operands,
        user_id: this.user.id,
        username: this.user.email
      }
      axios.post(`${process.env.VUE_APP_COMPUTE_URL}/compute/tools-execute`, payload)
        .then(response => {
          if (response) {
            item['operation_id'] = response.data.operation_id
            item['status'] = "RUNNING";
            this.operations[index] = item;
            Vue.notify({
              group: 'messages',
              type: 'success',
              text: 'Operation Launched',
            })
          }
        })
      //process.env.VUE_APP_COMPUTE_URL
    },
    async stopOperation(item, index) {
      ///compute/stop/<operation_id>
      if (item.operation_id) {
        axios.get(`${process.env.VUE_APP_COMPUTE_URL}/compute/stop/${item.operation_id}`)
          .then((response) => {
            item['status'] = "STOPPED";
            this.operations[index] = item;
            Vue.notify({
              group: 'messages',
              type: 'info',
              text: 'Operation has been Stop',
            })
          }).catch((error) => {
            console.log(error)
          })
      }
    },
  },

  watch: {
    actionTools() {
      this.operations = [];
      for (let i = 0; i < this.actionTools.length; i++) {
        let api_operands = JSON.parse(this.actionTools[i].api_operands)
        this.operations.push({
          operation: api_operands.op,
          function_name: this.actionTools[i].function_name,
          help: this.actionTools[i].help_text,
          running: false,
          status: this.actionTools[i].status ? this.actionTools[i].status : 'unbuilt-ready',
          percent: 0,
          // result: 'N/A',
          reports: this.actionTools[i].reports ? this.actionTools[i].reports : '',
          downloads: this.actionTools[i].downloads ? this.actionTools[i].downloads : '',
          operation_id: this.actionTools[i].operation_id ? this.actionTools[i].operation_id : '',
          id: this.actionTools[i].id ? this.actionTools[i].id : '',
          logs: this.actionTools[i].logs ? this.actionTools[i].logs : '',
          script: this.actionTools[i].script,
          api_entry: this.actionTools[i].api_entry,
          api_operands: api_operands,
          row_number: this.actionTools[i].row_number
        })
      }
    },
    statusTools() {
      for (let i = 0; i < this.statusTools.length; i++) {
        let api_operands = JSON.parse(this.actionTools[i].api_operands)
        for (let j = 0; j < this.operations.length; j++) {
          if (this.operations[j].operation == api_operands.op) {
            if (this.operations[j].operation_id != '') {
              axios.get(`${process.env.VUE_APP_COMPUTE_URL}/compute/status/${this.operations[j].operation_id}`)
                .then((response) => {
                  if (response.data['status'] == "REVOKED") {
                    this.operations[j].status = "STOPPED"
                  } else if (response.data['status'] == "PENDING") {
                    this.operations[j].status = "RUNNING"
                  } else {
                    this.operations[j].status = response.data['status']
                  }
                  this.operations[j].running = false
                  //  if(response.data['success']){
                  //     console.log(response.data)
                  //     this.operations[j].status = response.data['status']
                  //       this.operations[j].running = false
                  //  }else{

                  //  }
                })
            }

            // this.operations[j].info = this.jobOperations[i].description
            // this.operations[j].operation_id = this.jobOperations[i].uid
            // this.operations[j].id = this.jobOperations[i].id
            // this.operations[j].reports = this.jobOperations[i].reports
            //unbuilt-ready ilt-notready uilt'
          }
        }
      }
    }
  },
}
</script>
<style scoped>
.v-input__control .v-input__slot {
  margin: 0px !important;
}
</style>


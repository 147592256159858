<template>
  <div>
    <v-overlay :value="waiting">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialog" max-width="90%" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ operation_name }}</span>
        </v-card-title>
          <v-container grid-list-xl fluid>
            <v-textarea
              id="operation_log_screen"
              label="Operation logs"
              :value="operation_logs"
              rows="20"
            ></v-textarea>
          </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeLogs()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card max-width="100%">
      <v-card-title class="mb-6">
        <v-spacer></v-spacer>
          <v-btn dark color="blue-grey darken-1" :disabled="selected.length <= 1"  @click="resetSelectedOperations()">
            <v-icon left>mdi-refresh</v-icon> RESET
          </v-btn>
          <v-btn dark color="blue-grey darken-1" :disabled="selected.length <= 1" @click="launchSelectedOperations()" class="ml-1">
            <v-icon left>mdi-play</v-icon> Run ALL
          </v-btn>
      </v-card-title>

      <v-container grid-list-xl fluid>
        <v-layout row wrap>
          <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="operations"
                  :loading="jobOperationsLoading"
                  item-key="operation"
                  class="elevation-1"
                  style="width: 100%;"
                  show-select
                  hide-default-footer
                  :items-per-page="50"
          > 
            <template v-slot:item.running="{ item }">
              <v-btn v-if="item.running" text small @click="stopOperation(item)"><v-icon>mdi-stop</v-icon></v-btn>
              <v-btn v-else-if="item.status=='Not Ready'" text small @click="launchOperation(item)"><v-icon>mdi-play</v-icon></v-btn>
              <v-btn v-else text small @click="resetOperation(item)"><v-icon>mdi-refresh</v-icon></v-btn>
            </template>

            <template v-slot:item.percent="{ item }">
              <v-progress-linear
                v-model="item.percent"
                color="amber"
                height="25"
              >
                <template v-slot="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </template>

            <template v-slot:item.reports="{ item }">              
              <div class="d-flex justify-center align-center">
                <v-menu v-if="item.reports.length > 0" 
                  :rounded="'b-lg'" 
                  offset-y
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    >
                      Download
                      <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list color="grey darken-3">
                    <v-list-item
                      v-for="(report, index) in item.reports"
                      :key="index"
                      link
                    >
                      <v-list-item-title v-text="report.text" @click="openLink(report)"></v-list-item-title>                      
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                </v-menu>
                <div v-else>Not Ready</div>
              </div>
            </template>

            <template v-slot:item.settings="{ item }">
              <v-btn text small><v-icon>mdi-weather-sunny</v-icon></v-btn>
            </template>

            <template v-slot:item.logs="{ item }">
              <v-btn text small @click="viewLogs(item)" :disabled="item.status == 'Not Ready'"><v-icon>mdi-eye</v-icon></v-btn>
            </template>

          </v-data-table>
        </v-layout>
      </v-container>
    </v-card>
  </div>

</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import vSelect from 'vue-select'
  import axios from 'axios'

  import DashLayout from '../../containers/Layout'

  export default {
    name: 'JobControl',
    components: {
      DashLayout,
      vSelect
    },
    computed: {
      ...mapGetters(['activeElection', 'activeAuditJob', 'jobOperations', 'jobOperationsLoading', 'activeOperation', 'user'])
    },
    mounted() {
    },
    data () {
      return {
        waiting: false,
        selected: [],
        operations:  [
          {operation: 'precheck', name: 'Precheck', running: false, status: 'Ready', percent: 0, result: 'N/A', reports: [], settings: '', info: '', operation_id: '', id: ''},
          {operation: 'gen_bif', name: 'Prescan', running:  false, status: 'Not Ready', percent: 0, result: 'N/A', reports: [], settings: '', info: '', operation_id: '', id: ''},
          {operation: 'genstyles', name: 'Gen Styles', running:  false, status: 'Not Ready', percent: 0, result: 'N/A', reports: [], settings: '', info: '', operation_id: '', id: ''},
          {operation: 'gentemplates ', name: 'Gen Templates', running:  false, status: 'Not Ready', percent: 0, result: 'N/A', reports: [], settings: '', info: '', operation_id: '', id: ''},
          {operation: 'extractvote', name: 'Extract Vote', running:  false, status: 'Not Ready', percent: 0, result: 'N/A', reports: [], settings: '', info: '', operation_id: '', id: ''},
          {operation: 'cmpcvr', name: 'Cmp CVR', running:  false, status: 'Not Ready', percent: 0, result: 'N/A', reports: [], settings: '', info: '', operation_id: '', id: ''},
          {operation: 'adjudicate', name: 'Adjudication', running:  false, status: 'Not Ready', percent: 0, result: 'N/A', reports: [], settings: '', info: '', operation_id: '', id: ''},
          {operation: 'genreport', name: 'Final Report', running:  false, status: 'Not Ready', percent: 0, result: 'N/A', reports: [], settings: '', info: '', operation_id: '', id: ''},
          {operation: 'export', name: 'Export', running:  false, status: 'Not Ready', percent: 0, result: 'N/A', reports: [], settings: '', info: '', operation_id: '', id: ''},
          {operation: 'archive', name: 'Archive', running:  false, status: 'Not Ready', percent: 0, result: 'N/A', reports: [], settings: '', info: '', operation_id: '', id: ''},
        ],
        dialog: false,
        operation_name: '',
        operation_id: '',
        operation_logs: '',
        log_timer: null,
        headers: [
          { text: 'Operation', value: 'name', sortable: false, width: '10%'},
          { text: 'Action', value: 'running', sortable: false, width: '5%'},
          { text: 'Status', value: 'status', sortable: false, width: '10%'},
          { text: 'Percent', value: 'percent', sortable: false, width: '15%'},
          { text: 'Result', value: 'result', sortable: false, width: '5%'},
          { text: 'Info', value: 'info', sortable: false},          
          { text: 'Reports', value: 'reports', sortable: false, width: '10%'},
          { text: 'Settings', value: 'settings', sortable: false, width: '5%'},
          { text: 'Logs', value: 'logs', sortable: false, width: '5%'},
        ],
      }
    },
    methods: {
      async launchSelectedOperations(){
        for(let i = 0; i < this.selected.length; i++) {
          await this.launchOperation(this.selected[i])
        }
      },
      async launchOperation(operation) {
        this.waiting = true
        const payload = {
          job_id: this.activeAuditJob.id,
          operation: operation.operation,
          action: true
        }
        const ret = await this.$store.dispatch('LaunchJobOperation', payload)
        this.waiting = false
        return true
      },
      async resetSelectedOperations() {
        for(let i = 0; i < this.selected.length; i++) {
          await this.resetOperation(this.selected[i])
        }
      },
      async resetOperation(operation) {
        this.waiting = true
        if(operation.id != ''){
          const ret = await this.$store.dispatch('ResetJobOperation', operation.id)
        }        
        this.waiting = false
        return true
      },
      async stopOperation(operation) {
        this.waiting = true
        if(operation.id != ''){
          const ret = await this.$store.dispatch('StopJobOperation', operation.id)
        }        
        this.waiting = false
        return true
      },
      viewLogs(item) {
        this.operation_name = item.name
        this.operation_id = item.operation_id
        this.operation_logs = ''

        this.dialog = true
        this.log_timer = setInterval(this.updateLogs, 3000)
      },
      closeLogs() {
        clearInterval(this.log_timer)
        this.dialog = false
      },
      updateLogs() {
        // const url = 'https://server.auditengine.org/logs/operation-logs/' + this.operation_id + '/'
        const url = process.env.VUE_APP_ROOT_API + '/' + this.operation_id + '/'
        axios.get(url, {
            crossDomain: true,
            'Access-Control-Allow-Origin': '*'
        })
        .then(response => {
          if(this.operation_logs != response.data){
            this.operation_logs = response.data
            var textarea = document.getElementById('operation_log_screen')
            textarea.scrollTop = textarea.scrollHeight;
          }
        })
        .catch(e => {
          console.log(e)
        })
      },
      openLink(report){
        window.open(report.url, '_blank');
      }
    },
    watch: {
      selected(){
      },
      jobOperations() {
        for(let i = 0; i < this.jobOperations.length; i++) {
          for(let j = 0; j < this.operations.length; j++){
            if (this.operations[j].operation == this.jobOperations[i].operation) {
              this.operations[j].running = this.jobOperations[i].running
              this.operations[j].progress = this.jobOperations[i].progress
              this.operations[j].status = this.jobOperations[i].status
              this.operations[j].info = this.jobOperations[i].description
              this.operations[j].operation_id = this.jobOperations[i].uid
              this.operations[j].id = this.jobOperations[i].id
              this.operations[j].reports = this.jobOperations[i].reports
            }
          }
        }
      }
    }
  }
</script>

<style>
#operation_log_screen {
  font-family: "Courier New", Courier, monospace;
}
</style>
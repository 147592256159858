<template>
  <v-row class="justify-end pr-4">
    <v-spacer></v-spacer>
    <v-dialog v-model="dialog" max-width="500px" persistent>    
      <v-card class="reduce-row-gaps">
        <v-card-text class="picker-popup">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-if="!!id"
              v-model="file_name"
              label="File Name"
              validate-on-blur
              outlined
              :readonly="waiting"
              disabled
            ></v-text-field>
<!-- 
            <v-select
              v-else
              v-model="file_name"
              :items="adoptableFiles"
              label="File Name"   
              outlined
              :readonly="waiting"
            ></v-select>             -->
            <!-- <v-combobox
              v-else
              :items="adoptableFiles"
              v-model="selectedPermits"
              label="File Name"
              multiple
            
            ><template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item.id)"
                  v-bind="data.attrs"
                  :disabled="data.disabled"
                >
                  <v-avatar
                    class="accent white--text"
                    left
                    v-text="data.item.slice(0,1).toUpperCase()"
                  ></v-avatar>
                  {{ data.item }}
                </v-chip>
                <span
                  v-if="selectedPermits.length > 2"
                  class="white--text text-caption"
                >
                  (+{{ selectedPermits.length - 2 }} others)
                </span>
              </template>
            </v-combobox> -->
            <v-select
            v-else
              v-model="selectedPermits"
              :items="adoptableFiles"
              label="File Name"
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <v-avatar
                    class="accent white--text"
                    left
                    v-text="item.slice(0,1).toUpperCase()"
                  ></v-avatar>
                  {{ item }}
                </v-chip>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >
                  (+{{ selectedPermits.length - 1 }} others)
                </span>
              </template>
            </v-select>
          </v-col>

          <v-col cols="12">
            
          </v-col>

          <v-col cols="12">
            <v-select
            v-model="file_type"
            :items="file_types"
             item-text="description"
             item-value="value"
            label="File Type"   
            outlined
            :readonly="waiting"
          ></v-select>
          </v-col>

          <!--
          <v-col cols="12">
            <v-text-field
              v-model="etag"
              label="Etag"
              validate-on-blur
              outlined
              :readonly="waiting"
            ></v-text-field>
          </v-col> 
          -->

          <v-col cols="12">
            <v-textarea
                    clearable
                    clear-icon="cancel"
                    label="Description"
                    v-model="description"
                    outlined
                    :readonly="waiting"
            ></v-textarea>
          </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="close" :disabled="waiting">Close</v-btn>
          <!-- <v-btn v-if="user.role == 'Auditor' " color="blue darken-1" outlined @click="adopt"  :disabled="waiting"> -->
          <v-btn color="blue darken-1" outlined @click="adopt"  :disabled="waiting">
            {{ !!id ? "Update" : "Adopt" }}
            <v-progress-circular
              indeterminate
              color="primary"
              v-show="waiting"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>



<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'

  export default {
    name: 'FileAdoptDialog',
    components: {
    },
    computed: {
      ...mapGetters(['activeElection', 'adoptableFiles', 'user', 'loadUploadSets'])
    },
    mounted() {
      this.userValue = this.user
    },
    data() { 
      return {
        userValue: {},
        isHidden: false,
        dialog: false,
        waiting: false,
        id: null,
        status: null,
        file_name: '',
        // etag: '',
        description: '',
        file_types: [
          // {value: 'BIA', description: 'Ballot Image Archive'},
          // {value: 'CVR', description: 'Cast Vote Records'},
          // {value: 'OTH', description: 'Other File type'},
          // {value: 'DPT', description: 'Digital Poll Tapes'},
          // {value:'BSM',description:'Ballot Style Master'}
          // {value: 'EIF', description: 'Election Information'},
          // {value: 'BOF', description: 'Ballot Options'},
          // {value: 'STC', description: 'Manual Styles to Contests'},
          // {value: 'ANN', description: 'Annotations'},
          // {value: 'ADJ', description: 'Adjudications'},
          // {value: 'JOB', description: 'Job Configuration'},
          // {value: 'SUM', description: 'Summary Results'},
        ],
        file_type: 'BIA',
        selectedPermits: [],
      }
    },
    methods: {
      close() {
        this.dialog = false
        this.id = null
        this.selectedPermits = []
      },
      async show(item,rets) {
        
        if (rets) {
          rets.forEach((element) => {
            if (element.group == '') {
              element.arguments.forEach((ele) => {
                if (ele.argument == 'upload_file_types') {
                  let val = JSON.parse(ele.value)
                  let fileTypeObj = {}
                  for (var key in val) {
                      if (val.hasOwnProperty(key)) {
                        fileTypeObj = {value: key, description: val[key]}
                        this.file_types.push(fileTypeObj)
                      }
                  }
                }
              })
            }
          })
        }
        if(item.id) {
          this.id = item.id
          this.file_type = item.file_type
          this.file_name = item.file_name
          
          // this.etag = item.etag
          this.status = item.status
          this.description = item.description
        }
        else {
          this.file_type = 'BIA'        
          this.description = ''
          if(this.adoptableFiles.length > 0) {
            this.file_name = this.adoptableFiles[0]
          } else {
            this.file_name = ''
          }
        }
        this.dialog = true
      },
      async adopt(){
        this.waiting = true
        let fileString = '';
        this.selectedPermits.map((item, index) => {
          fileString = fileString + item
          if(this.selectedPermits.length !== index+1) {
            fileString = fileString + ','
          }
        })
        
        let payload = {
          file_type: this.file_type,
          file_name: fileString,
          // etag: this.etag,
          description: this.description
        }
       
        if(this.id) {
          payload.id = this.id
          payload.status = this.status
          const response = await this.$store.dispatch('UpdateUploadSet', payload)
          if(response['data']){
            this.$store.commit('apiError', response.data.error)
          }
          this.id = null
        } else {
          payload.election_id = this.activeElection.id
          const response = await this.$store.dispatch('AdoptUploadSet', payload)
          if(response['data']){
            this.$store.commit('apiError', response.data.error)
          }else{
            this.$store.dispatch('loadUploadSets', this.activeElection.id)
          }
        }
        this.waiting = false
        this.dialog = false
        this.selectedPermits = []
      },
    },
    watch: {
    }
  }
</script>
